/* /src/assets/scss/components/ImportComponent.scss */

.import-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  max-width: 1000px; /* Restrict maximum width */
  margin: 0 auto; /* Center horizontally */
}

.input-file,
.textarea {
  margin: 10px 0;
  width: 100%;
}

.input-file input[type="file"] {
  display: block;
  margin-bottom: 10px;
}

.textarea textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
}

.button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
}

.error {
  color: red;
  margin-top: 10px;
}

.table-container {
  width: 100%;
  overflow-x: auto; /* Horizontal scroll */
  margin-top: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
}
