// src/assets/scss/components/_navbar.scss

$nav-bg-color: #007bff;
$link-color: #fff;
$button-bg-color: #007bff;
$dropdown-bg-color: #fff;
$dropdown-text-color: #000;

.active {
  background-color: #f0f0f0 !important; // Lighter color for highlighting
  color: $nav-bg-color !important; // Change text color to match the background
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: bold;
}


.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $nav-bg-color;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.navbar-title {
  color: $link-color;
  font-size: 24px;
  font-weight: bold;
}

.navbar-links {
  display: flex;
  flex-grow: 1;
  justify-content: center; // Center the links
  margin-left: 20px; // Add some margin to ensure they are not too close to the edges
}

.nav-link {
  color: $link-color;
  text-decoration: none;
  margin: 0 15px;
  font-size: 18px;
}

.auth-buttons {
  display: flex;
  align-items: center;
}

.profile-container {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid $link-color;
  margin-right: 10px; // Add space between profile image and dropdown menu
}

.dropdown-menu {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: $dropdown-bg-color;
  color: $dropdown-text-color;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  z-index: 1000;
}

.dropdown-link {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: $dropdown-text-color;
}

.dropdown-button {
  display: block;
  padding: 10px 20px;
  border: none;
  background-color: $button-bg-color;
  color: $link-color;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}
