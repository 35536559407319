//
// Import component and page styles
@import 'components/_navbar';
@import 'pages/_home';

// Define color variables
$button-bg-color: #007bff;
$button-text-color: #fff;
$background-color: #f4f4f4; // Light background color

// Global styles
body {
  background-color: $background-color;
  margin: 0;
  font-family: Arial, sans-serif; // Optional: Set a global font-family
}

.home-content {
  text-align: center;
  padding: 20px;
  padding-top: 60px; // Ensure content isn't hidden behind the fixed Navbar
}

.button-container {
  margin-top: 20px;
}

.link-style {
  text-decoration: none;
  margin: 0 10px;
}

.button-style {
  padding: 10px 20px;
  font-size: 16px;
  color: $button-text-color;
  background-color: $button-bg-color;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
}
